import React from 'react'
import { PulseLoader } from 'react-spinners';
import { useTranslation } from 'react-i18next';
export default function Loader() {
    const [t, i18n] = useTranslation();
  return (
    <div className='h-screen w-screen flex items-center justify-center bg-customBlue-800'>
            <div className='flex flex-col gap-3 items-center justify-center'>
              <img src={require('../../../img/logos/logo_andino3.png')} alt={t("altImg.refugeLogo")} className='text-red w-[10rem] h-[10rem] flex items-center justify-center object-contain'/>
              <PulseLoader color="#fff" size={7}/>
              <p className='text-md text-white'> { t("home.welcome") } </p>
            </div>
    </div>
  )
}
