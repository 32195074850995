import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {I18nextProvider} from "react-i18next";
import i18next from 'i18next';
import masterTranslationEsp from "./data/translations/esp/masterTranslationEsp.json"
import masterTranslationEng from "./data/translations/eng/masterTranslationEng.json"
import { StyledEngineProvider } from '@mui/material'
import LanguageDetector from 'i18next-browser-languagedetector';

i18next
.use(LanguageDetector)
.init({
  interpolation: {escapeValue: false},
  fallbackLng: 'en',
  resources: {
    es: {
      translation: masterTranslationEsp
    },
    en :{
      translation: masterTranslationEng
    }
  }
})
;
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
      <React.StrictMode>
        <StyledEngineProvider injectFirst>
          <I18nextProvider i18n={i18next}>
            <App />
          </I18nextProvider>
        </StyledEngineProvider>
      </React.StrictMode>  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
