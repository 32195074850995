import React, { Suspense } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Loader from './components/components/Loader/Loader';
const Home = React.lazy(() => import('./components/pages/Home/Home'));
const Admin = React.lazy(() => import('./components/pages/Admin/Admin'));
const Dashboard = React.lazy(() => import('./components/pages/Dashboard/Dashboard'));
/* Se importan nuevas páginas */
const About = React.lazy(() => import('./components/pages/About/About'));
const Sheet = React.lazy(() => import('./components/pages/Sheet/Sheet'));
// se importan paginas del dashboard
const HomeDash = React.lazy(() => import('./components/pages/Dashboard/pages/home'));
const UsersDash = React.lazy(() => import('./components/pages/Dashboard/pages/users'));
const UserDash = React.lazy(() => import('./components/pages/Dashboard/pages/user'));
const SheetDash = React.lazy(() => import('./components/pages/Dashboard/pages/sheet'));
const LogbooksDash = React.lazy(() => import('./components/pages/Dashboard/pages/logbooks'));
const InstrumentsDash = React.lazy(() => import('./components/pages/Dashboard/pages/instruments'));
const VariablesDash = React.lazy(() => import('./components/pages/Dashboard/pages/variables'));

function App() {
  return (
    <BrowserRouter>
      <Suspense fallback={<Loader/>}>
        <>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/sheet" element={<Sheet />} />
            <Route path="/admin" element={<Admin />} />
            <Route path="/dashboard" element={<Dashboard />}>
              <Route index element={<HomeDash />} />
              <Route path="users" element={<UsersDash />} />
              <Route path="user" element={<UserDash />} />
              <Route path="sheet" element={<SheetDash />} />
              <Route path="logbooks" element={<LogbooksDash />} />
              <Route path="instruments" element={<InstrumentsDash />} />
              <Route path="variables" element={<VariablesDash />} />
            </Route>
          </Routes>
        </>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
